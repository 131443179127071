import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store/store";
import SessionActions from "@/store/store-session-actions";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      components: {
        default: () => import('@/views/Home.vue')
      },
      meta: {
        title: 'Home',
        description: '',
        viewTitle: '',
        viewDescription: '',
        icon: '',
        visible: true,
        authGroups: [],
      },
      children: [],
      beforeEnter: (to, from, next) => {
        return store.dispatch(SessionActions.AUTH).then(() => {
          next();
        }).catch(e => {
          next({ name: 'auth' })
        })
      }
    },
    {
      path: '/profile',
      name: 'profile',
      components: {
        default: () => import('@/views/Profile.vue')
      },
      meta: {
        title: 'Profile',
        description: '',
        viewTitle: '',
        viewDescription: '',
        icon: '',
        visible: true,
        authGroups: [],
      },
      children: [],
    },
    {
      path: '/auth',
      name: 'auth',
      components: {
        default: () => import('@/views/Auth.vue')
      },
      meta: {
        title: 'Auth',
        description: '',
        viewTitle: '',
        viewDescription: '',
        icon: '',
        visible: true,
        authGroups: [],
      },
      children: [],
      beforeEnter: (to, from, next) => {
        return store.dispatch(SessionActions.AUTH).then(() => {
          next({ name: 'home' })
        }).catch(e => {
          next();
        })
      }
    },
    {
      path: '/game',
      name: 'game',
      components: {
        default: () => import('@/views/Game.vue')
      },
      props: (route) => ({
        ...route.params
      }),
      meta: {
        title: 'Game',
        description: '',
        viewTitle: '',
        viewDescription: '',
        icon: '',
        visible: true,
        authGroups: [],
      },
      children: [],
    }
  ]
})
